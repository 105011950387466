import ReactGA from 'react-ga4';
import $ from 'jquery';
import { Usage } from 'types';
import { getAPIURL } from 'config/constants';
import { Room } from 'models';

export async function validateAdminToken(token: string) {
  try {
    const result = await $.ajax({
      url: `${getAPIURL()}/validateReviewerToken`,
      method: 'get',
      crossDomain: true,
      headers: {
        'reviewer-token': token,
      },
    });
    return result.status === 200;
  } catch {
    return false;
  }
}

export async function acceptOrReject3DMarker(
  markerId: number,
  isAccepted: boolean,
  adminToken: string,
) {
  try {
    const result = await $.ajax({
      url: `${getAPIURL()}/acceptOrReject3DMarker`,
      method: 'post',
      data: {
        marker_id: markerId,
        is_accepted: isAccepted,
      },
      headers: {
        'reviewer-token': adminToken,
      },
    });
    return result.status === 200;
  } catch {
    return false;
  }
}

export async function registerUsage(data: Usage) {
  $.ajax({
    url: `${getAPIURL()}/stats/registerVirtualVisit`,
    method: 'post',
    crossDomain: true,
    data,
  })
    .then((result) => {})
    .catch((e) => {});
  ReactGA.event({
    category: 'Virtual tour view',
    action: 'Image changed',
    value: data.listing_id, // optional, must be a number
  });
}

export async function sendVerificationCode(
  firstname: string,
  lastname: string,
  phone_number: string,
  email: string,
) {
  try {
    let language = 'en';
    if (window.navigator.language) {
      language = window.navigator.language.substring(0, 2);
    }
    const data = {
      firstname,
      lastname,
      phone_number,
      language,
      email,
    };
    const result = await $.ajax({
      url: `${getAPIURL()}/validateVRUser`,
      method: 'post',
      crossDomain: true,
      data,
    });
    return result;
  } catch (error) {
    return error.responseJSON;
  }
}

export async function verifyPhoneNumber(phone_number: string, verification_code: string) {
  try {
    const data = {
      phone_number,
      verification_code,
    };
    const result = await $.ajax({
      url: `${getAPIURL()}/verifyPhoneNumber`,
      method: 'post',
      crossDomain: true,
      data,
    });
    return result;
  } catch (error) {
    return false;
  }
}
export async function verifyRequest(
  firstname: string,
  lastname: string,
  phone_number: string,
  email: string,
) {
  try {
    let language = 'en';
    if (window.navigator.language) {
      language = window.navigator.language.substring(0, 2);
    }
    const data = {
      firstname,
      lastname,
      phone_number,
      language,
      email,
    };
    const result = await $.ajax({
      url: `${getAPIURL()}/verifyRequest`,
      method: 'post',
      crossDomain: true,
      data,
    });
    return result;
  } catch (error) {
    return error.responseJSON;
  }
}
export function callBrokerAPI(
  listing_id: number,
  broker_id: number,
  session_id: string,
  token: string,
) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const { agora_token } = await $.ajax({
        url: `${getAPIURL()}/callBroker`,
        method: 'post',
        crossDomain: true,
        data: {
          listing_id,
          broker_id,
          session_id,
          source: 'web',
        },
        headers: {
          token,
        },
      });
      resolve(agora_token);
    } catch (error) {
      reject(error);
    }
  });
}
export async function stopRinging(broker_id: number, session_id: string) {
  try {
    const data = {
      broker_id,
      session_id,
    };
    const result = await $.ajax({
      url: `${getAPIURL()}/stopRingingBroker`,
      method: 'post',
      crossDomain: true,
      data,
    });
    return result;
  } catch (error) {
    return false;
  }
}
export function getDate(days: number) {
  const now = new Date();
  const duedate = new Date(now);
  duedate.setDate(now.getDate() + days);
  return duedate;
}
export async function getListingForWeb(listing_id: number) {
  try {
    const res = await $.ajax({
      url: `${getAPIURL()}/getListingForWeb?listing_id=${listing_id}`,
      method: 'get',
      crossDomain: true,
    });
    return res;
  } catch (err) {
    console.log('[login] error: ', err);
  }
  return null;
}
export async function updateFloorplanRoom(token: string, _currentRoom: Room) {
  try {
    const data = _currentRoom;
    const result = await $.ajax({
      url: `${getAPIURL()}/updateFloorplanRoom`,
      method: 'post',
      crossDomain: true,
      data,
      headers: {
        token,
      },
    });
    return result;
  } catch (error) {
    return false;
  }
}
export async function moveFloorplanRoom(
  token: string,
  _currentRoom: any,
  keepRoom: boolean,
) {
  try {
    const data = _currentRoom;
    data.keepRoom = keepRoom;
    const result = await $.ajax({
      url: `${getAPIURL()}/moveFloorplanRoom`,
      method: 'post',
      crossDomain: true,
      data,
      headers: {
        token,
      },
    });
    return result;
  } catch (error) {
    return false;
  }
}
export async function updateMarkerWithNewOrder(token: string, markers: []) {
  try {
    const data = { markers };
    const result = await $.ajax({
      url: `${getAPIURL()}/updateMarkerOrder`,
      method: 'post',
      crossDomain: true,
      data,
      headers: {
        token,
      },
    });
    return result;
  } catch (error) {
    return false;
  }
}
export async function copyFloorplanRoom(
  token: string,
  listing_id: number,
  markers_to_copy: [],
  room_id: number,
) {
  try {
    const data = {
      listing_id,
      markers_to_copy,
      room_id,
    };
    const result = await $.ajax({
      url: `${getAPIURL()}/copyFloorplanRoom`,
      method: 'post',
      crossDomain: true,
      data,
      headers: {
        token,
      },
    });
    return result;
  } catch (error) {
    return false;
  }
}
export async function approveMarker(
  token: string,
  listing_id: number,
  marker_id: number,
) {
  try {
    const data = {
      listing_id,
      marker_id,
    };
    const result = await $.ajax({
      url: `${getAPIURL()}/validateMedia`,
      method: 'post',
      crossDomain: true,
      data,
      headers: {
        token,
      },
    });
    return result;
  } catch (error) {
    return false;
  }
}
export async function rejectMarker(token: string, marker_id: number) {
  try {
    const data = {
      marker_id,
    };
    const result = await $.ajax({
      url: `${getAPIURL()}/rejectMedia`,
      method: 'post',
      crossDomain: true,
      data,
      headers: {
        token,
      },
    });
    return result;
  } catch (error) {
    return false;
  }
}
export async function getRoomMarkers(token: string, room_id: number) {
  try {
    const res = await $.ajax({
      url: `${getAPIURL()}/roomMarker?room_id=${room_id}`,
      method: 'get',
      crossDomain: true,
      headers: {
        token,
      },
    });
    return res;
  } catch (err) {
    console.log('[login] error: ', err);
  }
  return null;
}
export async function login(email, password) {
  try {
    const data = { email, password };
    const res = await $.ajax({
      url: `${getAPIURL()}/login`,
      method: 'post',
      crossDomain: true,
      data,
    });
    return res;
  } catch (err) {
    console.log('[login] error: ', err);
  }
  return null;
}

export async function checkIfValidEsoftOrderFromId(
  esoft_order_id: number,
  listing_id: number,
) {
  try {
    const res = await $.ajax({
      url: `${getAPIURL()}/isValidEsoftOrder?esoft_order_id=${esoft_order_id}&listing_id=${listing_id}`,
      method: 'get',
      crossDomain: true,
    });
    const { exist } = res;
    return exist;
  } catch (err) {
    console.log('[login] error: ', err);
  }
  return null;
}
