import logoTop from './logo_top.svg';
import drag360White from './drag360White.png';
import zoomUp from './zoom_up.png';
import zoomDown from './zoom_down.png';
import logoHorizontal from './logo_horizontal.svg';
import prev from './prev.png';
import next from './next.png';

export const images = {
  logoTop,
  logoHorizontal,
  drag360White,
  zoomUp,
  zoomDown,
  prev,
  next,
};
